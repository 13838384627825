import { Application } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import ahoy from 'ahoy.js';
import * as Bootstrap from 'bootstrap';
import Swal from 'sweetalert2';

import AlbumGalleryController from '../controllers/album-gallery-controller.ts';
import FaceCropperController from '../controllers/face-cropper-controller.ts';
import HomeFeedController from '../controllers/home-feed-controller.ts';
import VideoController from '../controllers/video-controller.ts';

const stimulus = Application.start();
stimulus.register('album-gallery', AlbumGalleryController);
stimulus.register('face-cropper', FaceCropperController);
stimulus.register('home-feed', HomeFeedController);
stimulus.register('video', VideoController);

document.addEventListener('turbo:before-cache', function () {
  // Teardown Stimulus controllers
  for (const controller of stimulus.controllers) {
    if ('teardown' in controller && typeof controller.teardown === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      controller.teardown();
    }
  }

  // Close the navbar
  const navbarCollapse = document.querySelector('#navbarCollapse');
  if (navbarCollapse) {
    const bsCollapse = Bootstrap.Collapse.getInstance(navbarCollapse);
    bsCollapse?.hide();
  }
});

Turbo.setConfirmMethod(async (message) => {
  const result = await Swal.fire({
    text: message,
    icon: 'warning',
    showCancelButton: true,
    focusConfirm: false,
    focusCancel: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes!',
  });

  return result.isConfirmed;
});

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target ?? '/');
};

// Email Hiding
function setupEmails() {
  const at = / at /;
  const dot = / dot /g;
  const emails = document.querySelectorAll<HTMLSpanElement>('span.mailme');
  for (const element of emails) {
    if (element.textContent?.replaceAll !== undefined) {
      const addr = element.textContent.replace(at, '@').replaceAll(dot, '.');
      element.innerHTML = '<a href="mailto:' + addr + '" title="Send Email" class="footer_links">' + addr + '</a>';
    }
  }
}

document.addEventListener('turbo:load', setupEmails);
document.addEventListener('turbo:frame-load', setupEmails);

document.addEventListener('turbo:load', function () {
  ahoy.trackView();
});
