import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import { saveAs } from 'file-saver';
import PhotoSwipe, { SlideData } from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import scrollIntoView from 'scroll-into-view';
import Swal from 'sweetalert2';

import csrfToken from '../javascript/csrf.ts';
import JustifiedGallery from '../javascript/justified-gallery.ts';
import Infinite from '../javascript/waypoints-infinite.ts';

interface GalleryItem extends SlideData {
  /**
   * Photo ID
   */
  id: number;

  /**
   * Download URL
   */
  download?: string;
}

interface JSONMessageResponse {
  message: string;
}

interface JSONSuccessResponse {
  success: boolean;
}

export default class AlbumGalleryController extends Controller<HTMLDivElement> {
  private justifiedGallery?: JustifiedGallery;
  private photoswipeItems: GalleryItem[] = [];
  private infinite?: Infinite;
  private lightbox?: PhotoSwipeLightbox;
  private emailPhotoModalElement?: HTMLElement;
  private emailPhotoModal?: Modal;
  private addToAlbumModalElement?: HTMLElement;
  private addToAlbumModal?: Modal;

  connect() {
    this.emailPhotoModalElement = document.querySelector('#emailPhotoModal') ?? undefined;
    this.addToAlbumModalElement = document.querySelector('#addToAlbumModal') ?? undefined;

    if (this.emailPhotoModalElement) this.emailPhotoModal = new Modal(this.emailPhotoModalElement);
    if (this.addToAlbumModalElement) this.addToAlbumModal = new Modal(this.addToAlbumModalElement);

    this.setupButtonHandlers();

    for (const item of this.element.querySelectorAll<HTMLElement>('.photo')) {
      this.addPhoto(item);
    }

    this.justifiedGallery = new JustifiedGallery(this.element, {
      rowHeight: 170,
      maxRowHeight: 350,
      margins: 5,
      triggerEvent: this.justifiedGalleryEventHandler.bind(this),
    });

    this.justifiedGalleryRender(false);
  }

  teardown(): void {
    this.element.style.visibility = 'hidden';

    this.justifiedGallery?.destroy();
  }

  private justifiedGalleryEventHandler(event: string) {
    if (event === 'jg.complete') {
      this.element.style.visibility = 'visible';

      // Don't initialize infinite scroll until we render the gallery at least once
      if (!this.infinite) {
        this.infinite = new Infinite({
          element: this.element,
          more: '.next-link',
          items: '.photo',
          onAfterPageLoad: (images) => {
            this.addPhotos(images);
            this.justifiedGalleryRender(false);
          },
        });
      }
    }
  }

  private justifiedGalleryRender(rewind = true) {
    if (!this.justifiedGallery) return;

    if (rewind) {
      this.justifiedGallery.rewind();
    }

    // Update the entries list
    if (!this.justifiedGallery.updateEntries(!rewind)) return;

    // Init justified gallery
    this.justifiedGallery.init();
  }

  private addPhotos(images: HTMLElement[]) {
    for (const image of images) {
      this.addPhoto(image);
    }
  }

  private addPhoto(image: HTMLElement) {
    // push the item to the photoswipe list
    this.photoswipeItems.push({
      id: image.dataset.id ? Number.parseInt(image.dataset.id, 10) : -1,
      src: image.dataset.src,
      srcset: image.dataset.srcset,
      download: image.dataset.original,
      width: Number.parseInt(image.dataset.width ?? '', 10),
      height: Number.parseInt(image.dataset.height ?? '', 10),
      element: image,
    });

    const imageTag = image.querySelector('img');
    if (imageTag) {
      imageTag.addEventListener('click', () => this.startPhotoSwipe(image));
    }

    const deleteButton = image.querySelector('.btn-delete');
    if (deleteButton) {
      deleteButton.addEventListener('click', () => this.deleteImage(image));
    }

    const hideButton = image.querySelector('.btn-hide');
    if (hideButton) {
      hideButton.addEventListener('click', () => this.hideImage(image));
    }
  }

  startPhotoSwipe(image: HTMLElement) {
    if (!image.dataset.id) return;

    // Get the index of the photo we need to launch at
    const index = this.photoswipeItems.findIndex((item) => {
      return item.id === Number.parseInt(image.dataset.id ?? '', 10);
    });

    this.lightbox = new PhotoSwipeLightbox({
      dataSource: this.photoswipeItems,
      loop: false,
      preload: [3, 3],
      pswpModule: PhotoSwipe,
      bgOpacity: 1,
      clickToCloseNonZoomable: false,
      secondaryZoomLevel: 1,
      maxZoomLevel: 2,
      trapFocus: false,
    });

    // Add listener to see when we get near the end of the album
    this.lightbox.on('change', () => {
      if (!this.lightbox?.pswp) return;

      const index = this.lightbox.pswp.currIndex;

      if (index > this.photoswipeItems.length - 5 && this.infinite?.canLoadMore()) {
        this.infinite.triggerHandler();
      }

      const item = this.photoswipeItems[index];

      if (item?.element) {
        scrollIntoView(item.element);
      }
    });

    this.lightbox.on('close', () => {
      if (!this.lightbox?.pswp) return;

      if (this.lightbox.pswp.currIndex) {
        const index = this.lightbox.pswp.currIndex;
        const item = this.photoswipeItems[index || 0];

        if (item?.element) {
          scrollIntoView(item.element);
        }
      }
    });

    this.lightbox.addFilter('numItems', () => {
      return Number.parseInt(this.element.dataset.count ?? '0');
    });

    this.lightbox.on('uiRegister', () => {
      this.lightbox?.pswp?.ui?.registerElement({
        name: 'album-button',
        order: 8,
        isButton: true,
        html: {
          isCustomSVG: true,
          inner:
            '<path d="m9.49,17.23l5.65,5.27c.23.22.54.34.87.34s.63-.12.87-.34l5.65-5.27c.95-.88,1.49-2.12,1.49-3.42v-.18c0-2.18-1.58-4.05-3.73-4.41-1.43-.24-2.88.23-3.89,1.25l-.38.38-.38-.38c-1.02-1.02-2.47-1.48-3.89-1.25-2.15.36-3.73,2.22-3.73,4.41v.18c0,1.3.54,2.54,1.49,3.42Z" id="pswp__icn-album" />',
          outlineID: 'pswp__icn-album',
        },
        onClick: () => {
          this.showAddToAlbum();
        },
      });
    });

    this.lightbox.on('uiRegister', () => {
      this.lightbox?.pswp?.ui?.registerElement({
        name: 'email-button',
        order: 8,
        isButton: true,
        html: {
          isCustomSVG: true,
          inner:
            '<path d="m9.5,10c-.83,0-1.5.67-1.5,1.5,0,.47.22.92.6,1.2l6.8,5.1c.36.27.84.27,1.2,0l6.8-5.1c.38-.28.6-.73.6-1.2,0-.83-.67-1.5-1.5-1.5,0,0-13,0-13,0Zm-1.5,3.5v6.5c0,1.1.9,2,2,2h12c1.1,0,2-.9,2-2v-6.5l-6.8,5.1c-.71.53-1.69.53-2.4,0l-6.8-5.1Z" id="pswp__icn-email" />',
          outlineID: 'pswp__icn-email',
        },
        onClick: () => {
          this.showEmailPhoto();
        },
      });
    });

    this.lightbox.on('uiRegister', () => {
      this.lightbox?.pswp?.ui?.registerElement({
        name: 'download-button',
        order: 8,
        isButton: true,
        html: {
          isCustomSVG: true,
          inner:
            '<path d="m17,9c0-.56-.44-1-1-1s-1,.44-1,1v7.59l-2.3-2.3c-.39-.39-1.03-.39-1.41,0s-.39,1.03,0,1.41l4,4c.39.39,1.02.39,1.41,0l4-4c.39-.39.39-1.03,0-1.41s-1.02-.39-1.41,0l-2.29,2.3v-7.59Zm-7,10c-1.11,0-2,.9-2,2v1c0,1.1.89,2,2,2h12c1.1,0,2-.9,2-2v-1c0-1.1-.9-2-2-2h-3.17l-1.41,1.41c-.78.78-2.05.78-2.83,0l-1.41-1.41h-3.17Zm11.5,1.75c.41,0,.75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75Z" id="pswp__icn-download" />',
          outlineID: 'pswp__icn-download',
        },
        onClick: () => {
          this.downloadPhoto();
        },
      });
    });

    this.lightbox.init();
    this.lightbox.loadAndOpen(index);
  }

  private setupButtonHandlers() {
    const emailSubmitButton = this.emailPhotoModalElement?.querySelector('#email-photo');
    const addToAlbumButton = this.addToAlbumModalElement?.querySelector('#add-to-album');

    emailSubmitButton?.addEventListener('click', () => {
      this.emailPhoto();
    });

    addToAlbumButton?.addEventListener('click', () => {
      this.addToAlbum();
    });
  }

  showAddToAlbum() {
    this.addToAlbumModal?.show();
  }

  showEmailPhoto() {
    if (!this.emailPhotoModalElement || !this.emailPhotoModal) return;

    const destinationNameField = this.emailPhotoModalElement.querySelector<HTMLInputElement>('#email-dest-name');
    const destinationEmailField = this.emailPhotoModalElement.querySelector<HTMLInputElement>('#email-dest-email');
    const noteField = this.emailPhotoModalElement.querySelector<HTMLInputElement>('#email-note');

    if (destinationNameField) destinationNameField.value = '';
    if (destinationEmailField) destinationEmailField.value = '';
    if (noteField) noteField.value = '';

    this.emailPhotoModal.show();
  }

  downloadPhoto() {
    const item = this.lightbox?.pswp?.currSlide?.data as GalleryItem;

    if (item.download) {
      if (window.webkit?.messageHandlers?.colemanApp) {
        const message = JSON.stringify({ action: 'downloadPhoto', url: item.download });
        window.webkit.messageHandlers.colemanApp.postMessage(message);
      } else if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        window.open(item.download);
      } else {
        const filename = item.download.split('/').pop();
        saveAs(item.download, filename);
      }
    }
  }

  async addToAlbum() {
    if (!this.addToAlbumModal || !this.addToAlbumModalElement) return;

    const item = this.lightbox?.pswp?.currSlide?.data as GalleryItem;

    const photoId = item.id;

    const albumSelect = this.addToAlbumModalElement.querySelector<HTMLSelectElement>('#add-to-album-select');
    const albumId = albumSelect?.value;

    try {
      const response = await fetch('/myalbums/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
        body: JSON.stringify({
          album: albumId,
          photo: photoId,
        }),
      });
      const json = (await response.json()) as JSONMessageResponse;

      Swal.fire({
        text: `${json.message}`,
        icon: response.ok ? 'success' : 'error',
        timer: response.ok ? 2000 : undefined,
      });
      if (response.ok) this.addToAlbumModal.hide();
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      Swal.fire({ icon: 'error', text: `An unknown error ocurred while adding to the album: ${error}` });
    }
  }

  async emailPhoto() {
    if (!this.emailPhotoModalElement || !this.emailPhotoModal) return;

    const item = this.lightbox?.pswp?.currSlide?.data as GalleryItem;

    const photoId = item.id;
    const destinationNameField = this.emailPhotoModalElement.querySelector<HTMLInputElement>('#email-dest-name');
    const destinationEmailField = this.emailPhotoModalElement.querySelector<HTMLInputElement>('#email-dest-email');
    const noteField = this.emailPhotoModalElement.querySelector<HTMLInputElement>('#email-note');

    try {
      const response = await fetch('/photos/share/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
        body: JSON.stringify({
          photo: photoId,
          destination_name: destinationNameField?.value,
          destination_email: destinationEmailField?.value,
          note: noteField?.value,
        }),
      });
      const json = (await response.json()) as JSONMessageResponse;

      Swal.fire({
        text: `${json.message}`,
        icon: response.ok ? 'success' : 'error',
      });
      if (response.ok) this.emailPhotoModal.hide();
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      Swal.fire({ icon: 'error', text: `An unknown error ocurred while sending the photo: ${error}` });
    }
  }

  async deleteImage(image: HTMLElement) {
    try {
      const confirmation = await Swal.fire({
        title: 'Remove Photo',
        text: 'Are you sure you would like to remove this photo?',
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        focusCancel: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
      });

      if (!confirmation.isConfirmed) return;

      const response = await fetch('/myalbums/remove', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
        body: JSON.stringify({
          id: image.dataset.personalId,
        }),
      });
      const json = (await response.json()) as JSONSuccessResponse;

      if (json.success) {
        image.remove();
        this.justifiedGalleryRender();
        this.decrementPhotoCount();
        Swal.fire({
          text: 'Photo was removed from the album',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      Swal.fire({ icon: 'error', text: `An unknown error ocurred while removing this photo: ${error}` });
    }
  }

  async hideImage(image: HTMLElement) {
    try {
      const confirmation = await Swal.fire({
        title: 'Hide Photo',
        text: 'Are you sure you would like to hide this photo?',
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        focusCancel: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, hide!',
      });

      if (!confirmation.isConfirmed) return;

      const response = await fetch('/faces/hide', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
        body: JSON.stringify({
          person: image.dataset.personId,
          face: image.dataset.faceId,
        }),
      });
      const json = (await response.json()) as JSONSuccessResponse;

      if (json.success) {
        image.remove();
        this.justifiedGalleryRender();
        this.decrementPhotoCount();
        Swal.fire({
          text: 'Photo was removed from the album',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      Swal.fire({ icon: 'error', text: `An unknown error ocurred while hiding this photo: ${error}` });
    }
  }

  private decrementPhotoCount() {
    const counts = document.querySelectorAll('.photo-count');

    for (const countElement of counts) {
      const text = countElement.innerHTML;
      const count = /\d+/.exec(text);
      if (text && count) {
        const newCount = Number.parseInt(count[0], 10) - 1;
        countElement.innerHTML = text.replace(/\d+/, newCount.toString());
      }
    }
  }
}
